import { createSlice } from "@reduxjs/toolkit";
import { setAnimated, setDisabled } from "./swipeable";
import { role, target } from "config";
import { initialStoriesOffline } from "Store/storiesOfflineFirstManager";
import { isOfflineFirst } from "config";

const STORAGE_KEY = "showhere-accountswithprojects";
const getPersistedAccountsWithProjectsOffline = () =>
  JSON.parse(window.localStorage.getItem(STORAGE_KEY)) ?? [];
const setPersistedAccountsWithProjectsOffline = (accountsWithProjects) =>
  window.localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify(accountsWithProjects)
  );

const structureSlice = createSlice({
  name: "structure",
  initialState: getInitialStructureState(initialStoriesOffline),
  reducers: {
    replaceStructure(state, action) {
      state.contentType = "Presentation"; // "Presentation" | "Sequence"
      state.storyId = action.payload.storyId;
      state.sequenceId = action.payload.sequenceId;
      state.stories = action.payload.stories;
    },
    reset(state) {
      state.contentType = "Presentation";
      state.sessionStarted = false;
      state.storyId = getInitialStoryId(state.stories);
      state.sequenceId = null;
      state.stories.forEach((story) => {
        story.columnIndex = 0;
        story.columns.forEach((column) => {
          column.slideIndex = 0;
        });
        story.sequences?.forEach((sequence) => {
          sequence.slideIndex = 0;
        });
      });
    },
    setAccountsWithProjects(state, action) {
      state.accountsWithProjects = action.payload;
      if (isOfflineFirst) {
        setPersistedAccountsWithProjectsOffline(action.payload);
      }
    },
    resetStoryId(state) {
      state.storyId = null;
    },
    startSession(state) {
      state.sessionStarted = true;
    },
    endSession(state) {
      state.sessionStarted = false;
    },
    _goTo(state, action) {
      const { s, sequenceId, x, y = null } = action.payload;
      state.contentType = sequenceId ? "Sequence" : "Presentation";
      const story = state.stories.find((story) => story.id === s);
      state.storyId = s;
      if (state.contentType === "Sequence") {
        state.sequenceId = sequenceId;
        const sequence = story.sequences.find(({ id }) => id === sequenceId);
        sequence.slideIndex = x;
      } else {
        story.columnIndex = x;
        if (typeof y === "number") story.columns[x].slideIndex = y;
      }
    },
    // useKeyboardArrows: travel through columns, using up/down/left/right (deprecated, pre-sequences)
    goToAdjacent(state, action) {
      if (state.storyId === null) return;
      const story = state.stories.find((story) => story.id === state.storyId);
      const direction = action.payload;
      if (direction === "left") {
        if (story.columnIndex === 0) return;
        story.columnIndex--;
      } else if (direction === "right") {
        if (story.columnIndex === story.columns.length - 1) return;
        story.columnIndex++;
      } else {
        const column = story.columns[story.columnIndex];
        if (direction === "up") {
          if (column.slideIndex === 0) return;
          column.slideIndex--;
        } else if (direction === "down") {
          if (column.slideIndex === column.slides.length - 1) return;
          column.slideIndex++;
        }
      }
    },
    // useKeyboardArrows: cycle through slides regardless of column structure, using left/right
    goToPrevious({ storyId, sequenceId, stories }, action) {
      if (storyId === null) return;
      const story = stories.find((story) => story.id === storyId);
      if (sequenceId) {
        const sequence = story.sequences.find(({ id }) => id === sequenceId);
        if (sequence.slideIndex > 0) {
          sequence.slideIndex--;
        }
      } else {
        const column = story.columns[story.columnIndex];
        if (column.slideIndex > 0) {
          column.slideIndex--;
        } else if (story.columnIndex > 0) {
          const targetColumnIndex = story.columnIndex - 1;
          story.columnIndex = targetColumnIndex;
          story.columns[targetColumnIndex].slideIndex =
            story.columns[targetColumnIndex].slides.length - 1;
        }
      }
    },
    goToNext({ storyId, sequenceId, stories }, action) {
      if (storyId === null) return;
      const story = stories.find((story) => story.id === storyId);
      if (sequenceId) {
        const sequence = story.sequences.find(({ id }) => id === sequenceId);
        if (sequence.slideIndex < sequence.slides.length - 1) {
          sequence.slideIndex++;
        }
      } else {
        const column = story.columns[story.columnIndex];
        if (column.slideIndex < column.slides.length - 1) {
          column.slideIndex++;
        } else if (story.columnIndex < story.columns.length - 1) {
          const targetColumnIndex = story.columnIndex + 1;
          story.columnIndex = targetColumnIndex;
          story.columns[targetColumnIndex].slideIndex = 0;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("stories/setStories", (state, action) => {
        const accountsWithProjects = state.accountsWithProjects;
        return {
          ...getInitialStructureState(action.payload),
          accountsWithProjects,
        };
      })
      .addCase("stories/addStory", (state, action) => {
        const newStoryState = getInitialStructureStateForStory(action.payload);
        const idx = state.stories.findIndex(
          (story) => story.id === action.payload.id
        );
        if (idx === -1) {
          state.stories.push(newStoryState);
        } else {
          state.stories[idx] = newStoryState;
        }
      })
      .addCase("stories/removeStory", (state, action) => {
        const idx = state.stories.findIndex(
          (story) => story.id === action.payload.id
        );
        if (idx !== -1) {
          state.stories.splice(idx, 1);
        }
      })
      .addCase("stories/addSequence", (state, action) => {
        const { story, sequence } = action.payload;
        const storyIdx = state.stories.findIndex(({ id }) => id === story.id);
        if (storyIdx === -1) return;
        const s = getInitialStructureStateForSequence(story.id, sequence);
        if (state.stories[storyIdx].sequences) {
          state.stories[storyIdx].sequences.push(s);
        } else {
          state.stories[storyIdx].sequences = [s];
        }
      })
      .addCase("stories/updateSequence", (state, action) => {
        const { story, sequence } = action.payload;
        const storyIdx = state.stories.findIndex(({ id }) => id === story.id);
        if (storyIdx === -1) return;
        const sequenceIdx = state.stories[storyIdx].sequences.findIndex(
          ({ id }) => id === sequence.id
        );
        if (sequenceIdx === -1) return;
        state.stories[storyIdx].sequences[sequenceIdx] =
          getInitialStructureStateForSequence(story.id, sequence);
      });
  },
});

function getInitialStoryId(stories) {
  let initialStoryId = null;
  if (stories.length === 1 && target === "local" && role === "listen") {
    initialStoryId = stories[0].id;
  }
  return initialStoryId;
}

function getInitialStructureState(stories) {
  return {
    contentType: "Presentation",
    sessionStarted: false,
    storyId: getInitialStoryId(stories),
    sequenceId: null,
    accountsWithProjects: getPersistedAccountsWithProjectsOffline(),
    stories: stories.flatMap((story) =>
      getInitialStructureStateForStory(story)
    ),
  };
}

function getInitialStructureStateForStory(story) {
  const storyState = {
    accountId: story.accountId,
    projectId: story.projectId,
    id: story.id,
    title: story.title,
    thumbnail: story.thumbnail,
    publishedAt: story.publishedAt,
    columnIndex: 0,
    columns: story.columnGroups
      .flatMap(({ columns }) => columns)
      .flatMap((column, columnIndex) => ({
        thumbnail: column.thumbnail,
        slideIndex: 0,
        slides: column.slides.map((slide, y) => ({
          title: slide.title,
          position: {
            s: story.id,
            x: columnIndex,
            y,
          },
        })),
      })),
    sequences: story.sequences?.map((sequence) =>
      getInitialStructureStateForSequence(story.id, sequence)
    ),
    resetRoomControlCommands: story.resetRoomControlCommands,
  };
  if (story.mediaCompletedAt) {
    storyState.mediaCompletedAt = story.mediaCompletedAt;
  }
  return storyState;
}

function getInitialStructureStateForSequence(storyId, sequence) {
  return {
    id: sequence.id,
    name: sequence.name,
    slideIndex: 0,
    slides: sequence.slides.map((slide, x) => ({
      title: slide.title,
      thumbnail: slide.thumbnail,
      position: {
        s: storyId,
        sequenceId: sequence.id,
        x,
        y: 0,
      },
    })),
  };
}

export const {
  replaceStructure,
  reset,
  setAccountsWithProjects,
  resetStoryId,
  goToAdjacent,
  goToPrevious,
  goToNext,
  startSession,
  endSession,
} = structureSlice.actions;

export const selectSessionStarted = (state) => state.structure.sessionStarted;
export const selectContentType = (state) => state.structure.contentType;
export const selectStoryId = (state) => state.structure.storyId;
export const selectAccountsWithProjects = (state) =>
  state.structure.accountsWithProjects;
export const selectStories = (state) => state.structure.stories;
export const selectStory = (state) => {
  const { storyId, stories } = state.structure;
  if (storyId === null) return null;
  return stories.find((s) => s.id === storyId);
};
export const selectSequenceId = (state) => state.structure.sequenceId;
export const selectSequence = (state) => {
  const { contentType, storyId, sequenceId, stories } = state.structure;
  if (contentType !== "Sequence" || storyId === null || sequenceId === null)
    return null;
  const story = stories.find((s) => s.id === storyId);
  return story.sequences.find((s) => s.id === sequenceId);
};

export const goTo =
  (position, preventAnimatedSlideTransition = false) =>
  (dispatch, getState) => {
    if (getState().structure.sessionStarted === false) {
      dispatch(startSession());
    }

    if (preventAnimatedSlideTransition === true) {
      dispatch(setAnimated({ x: false, y: false }));
    } else {
      const { s, sequenceId, x, y = null } = position;
      const { storyId, stories } = getState().structure;
      if (storyId !== null) {
        const story = stories.find((story) => story.id === s);

        let col;
        let sli;
        if (sequenceId) {
          const sequence = story.sequences.find(({ id }) => id === sequenceId);
          col = sequence.slideIndex;
          sli = 0;
        } else {
          col = story.columnIndex;
          sli = story.columns[x].slideIndex;
        }

        const animatedConfig = {
          x: !(x > col + 1 || x < col - 1 || s !== storyId),
          y: !(y > sli + 1 || y < sli - 1 || s !== storyId),
        };
        dispatch(setAnimated(animatedConfig));
      }
    }
    dispatch(setDisabled(false));
    dispatch(structureSlice.actions._goTo(position));
  };

export default structureSlice.reducer;
