import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import {
  initialStoriesOffline,
  diffLatestPublishedAgainstOfflineCached,
} from "Store/storiesOfflineFirstManager";
import { TransformApiResponse } from "content";
import {
  role,
  dataProvider,
  dataProviderScope,
  isWKWebView,
  isElectronPresenter,
  isMarketingSuiteViewer,
  isOfflineFirst,
} from "config";
import { setAccountsWithProjects } from "Store/structure";

export const getStories = createAsyncThunk(
  "stories/getStories",
  async (params, thunkAPI) => {
    try {
      let dataProviderModified = dataProvider;
      if (
        dataProvider === "api:auth" &&
        role === "listen" &&
        typeof process.env.REACT_APP_API_CONTENT_KEY !== "undefined"
      ) {
        console.warn(
          "[2/2] Overriding dataProvider from `api:auth` to `api:key` for role `listen`"
        );
        dataProviderModified = "api:key";
      }
      const stories = await getStoriesData(dataProviderModified, thunkAPI);
      if (dataProvider.startsWith("api:") && isOfflineFirst === true) {
        diffLatestPublishedAgainstOfflineCached({
          latestPublished: stories,
          offlineCached: thunkAPI.getState().stories.entities,
          selectedSequenceId: thunkAPI.getState().structure.sequenceId,
          dispatch: thunkAPI.dispatch,
        });
      } else {
        thunkAPI.dispatch(setStories(stories));
      }
      return stories;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  }
);

export const storiesSlice = createSlice({
  name: "stories",
  initialState: {
    entities: initialStoriesOffline,
    isLoading: false,
    firstFetch: {
      loading: initialStoriesOffline.length === 0 ? true : false,
      error: null,
    },
  },
  reducers: {
    setStories(state, action) {
      state.firstFetch.loading = false;
      state.firstFetch.error = null;
      state.entities = action.payload;
    },
    addStory(state, action) {
      const existingStory = state.entities.find(
        ({ id }) => id === action.payload.id
      );
      const existingStoryIndex = state.entities.findIndex(
        ({ id }) => id === action.payload.id
      );
      if (existingStory) {
        state.entities[existingStoryIndex] = action.payload;
      } else {
        state.entities.push(action.payload);
      }
      state.entities.sort((a, b) => a.order - b.order);
    },
    removeStory(state, action) {
      const idx = state.entities.findIndex(
        (story) => story.id === action.payload.id
      );
      if (idx !== -1) {
        state.entities.splice(idx, 1);
      }
    },
    addSequence(state, action) {
      const { story, sequence } = action.payload;
      const storyIdx = state.entities.findIndex(({ id }) => id === story.id);
      if (storyIdx === -1) return;
      if (state.entities[storyIdx].sequences) {
        state.entities[storyIdx].sequences.push(sequence);
        state.entities[storyIdx].sequences.sort((a, b) => {
          const aTime = new Date(a.publishedAt).getTime();
          const bTime = new Date(b.publishedAt).getTime();
          return bTime - aTime;
        });
      } else {
        state.entities[storyIdx].sequences = [sequence];
      }
    },
    updateSequence(state, action) {
      const { story, sequence } = action.payload;
      const storyIdx = state.entities.findIndex(({ id }) => id === story.id);
      if (storyIdx === -1) return;
      const sequenceIdx = state.entities[storyIdx].sequences.findIndex(
        ({ id }) => id === sequence.id
      );
      if (sequenceIdx === -1) return;
      state.entities[storyIdx].sequences[sequenceIdx] = sequence;
      state.entities[storyIdx].sequences.sort((a, b) => {
        const aTime = new Date(a.publishedAt).getTime();
        const bTime = new Date(b.publishedAt).getTime();
        return bTime - aTime;
      });
    },
    removeSequence(state, action) {
      const { story, sequence } = action.payload;
      const storyIdx = state.entities.findIndex(({ id }) => id === story.id);
      if (storyIdx === -1) return;
      const sequenceIdx = state.entities[storyIdx].sequences.findIndex(
        ({ id }) => id === sequence.id
      );
      if (sequenceIdx === -1) return;
      state.entities[storyIdx].sequences.splice(sequenceIdx, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.firstFetch.loading = false;
        state.firstFetch.error = null;
      })
      .addCase(getStories.rejected, (state, action) => {
        state.isLoading = false;
        state.firstFetch.loading = false;
        state.firstFetch.error = action.payload.message;
      });
  },
});

const getStoriesData = async (dataProvider, thunkAPI) => {
  let request;
  if (dataProvider === "disk") {
    request = window.fetch(`stories/${process.env.REACT_APP_NAMESPACE}.json`);
  } else if (dataProvider === "api:key") {
    request = window.fetch(
      `${process.env.REACT_APP_API_URL}sync/non-auth/presentations`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-content-api-key": process.env.REACT_APP_API_CONTENT_KEY,
        },
      }
    );
  } else if (dataProvider === "api:auth") {
    const {
      accountIds = null,
      projectIds = null,
      presentationIds,
    } = dataProviderScope;
    const fetchParams = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-auth-token-type": "sync",
      },
      body: JSON.stringify({
        ...(accountIds && { accountIds }),
        ...(projectIds && { projectIds }),
        ...(presentationIds && { presentationIds }),
      }),
    };
    if (isWKWebView) {
      const token = localStorage.getItem("auth-token");
      if (!token) throw Error("Missing auth-token");
      fetchParams.headers["x-auth-token"] = token;
    } else {
      fetchParams.credentials = "include";
    }
    request = window.fetch(
      `${process.env.REACT_APP_API_URL}sync/auth/presentations?groupedByAccountAndProject`,
      fetchParams
    );
  } else {
    throw Error(`Unrecognised dataProvider: ${dataProvider}`);
  }
  const response = await request;

  if (response.status === 401) {
    throw Error("Access denied");
  }

  let stories = await response.json();

  if (dataProvider === "api:auth") {
    const accountsWithProjects = stories.accounts
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((account) => ({
        id: account.id,
        name: account.name,
        projects: account.projects
          .filter(({ presentations }) => presentations.length > 0)
          .sort((a, b) => a.order > b.order)
          .map((project) => ({
            id: project.id,
            name: project.name,
            presentations: project.presentations
              .sort((a, b) => a.order > b.order)
              .map((presentation) => presentation.id),
          })),
      }));
    thunkAPI.dispatch(setAccountsWithProjects(accountsWithProjects));

    stories = stories.accounts.flatMap((account) =>
      account.projects.flatMap((project) => project.presentations)
    );
  }

  if (stories.length === 0) {
    switch (dataProvider) {
      case "api:auth":
        throw Error("No presentations available in authenticated user's scope");
      case "api:key":
        throw Error("No presentations available in content-key scope");
      default:
        throw Error("No presentations available");
    }
  }

  if (dataProvider === "disk") {
    return stories;
  } else if (dataProvider.startsWith("api:")) {
    if (stories.error) {
      throw Error(stories.error);
    }

    const presentations = stories.filter((p) => p.contentStatus !== "draft");

    // determine the base URL for slide media
    let mediaBaseUrlTemplate;
    if (isWKWebView || isElectronPresenter) {
      // 1. Universal file system
      mediaBaseUrlTemplate = `presentations/p-#PRES_ID#/v-#PRES_VERSION#/`;
    } else if (isMarketingSuiteViewer) {
      // 2. MacOS file system
      mediaBaseUrlTemplate = `${process.env.REACT_APP_LOCAL_MEDIA_BASE_URL}presentation-#PRES_ID#/version-#PRES_VERSION#/`;
    } else {
      // 3. Live from AWS S3
      mediaBaseUrlTemplate = `${process.env.REACT_APP_API_MEDIA_BASE_URL}#PRES_ID#/`;
    }

    // determine the base URL for navigation thumbnails
    let screenCapsBaseUrlTemplate;
    if (isWKWebView || isElectronPresenter) {
      // 1. Universal file system
      screenCapsBaseUrlTemplate = `presentations/p-#PRES_ID#/v-#PRES_VERSION#/thumbs/`;
    } else if (isMarketingSuiteViewer) {
      // 2. MacOS file system
      screenCapsBaseUrlTemplate = `${process.env.REACT_APP_LOCAL_MEDIA_BASE_URL}presentation-#PRES_ID#/version-#PRES_VERSION#/thumbs/`;
    } else {
      // 3. Live from AWS S3
      screenCapsBaseUrlTemplate = `${process.env.REACT_APP_API_MEDIA_BASE_URL}screen_captures/#PRES_ID#/thumbs/`;
    }

    return TransformApiResponse({
      presentations,
      mediaBaseUrlTemplate,
      screenCapsBaseUrlTemplate,
    });
  } else {
    throw Error(`Unrecognised dataProvider: ${dataProvider}`);
  }
};

export const {
  setStories,
  addStory,
  removeStory,
  addSequence,
  updateSequence,
  removeSequence,
} = storiesSlice.actions;

export const selectStories = createSelector(
  (state) => state.stories.entities,
  (entities) => entities
);

export const selectFirstFetch = (state) => state.stories.firstFetch;
export const selectIsLoading = (state) => state.stories.isLoading;

export default storiesSlice.reducer;
