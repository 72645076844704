import { role, isWKWebView, isWKWebViewTrackingAuthorized } from "../config";
import {
  SessionManager,
  Beacon,
  PageBlur,
  HTTPClient,
  HTTPClientConfig,
} from "@showhereco/slide-types";
import { EnrichedAnalyticsEvent, AnalyticsSession } from "./model";
import store from "../Store";
import { selectUser } from "../Store/auth";
import { selectSequence, selectStory } from "Store/structure";
import { toNum } from "Helpers/numbers";

type CustomAPIShape = {
  accountId: number;
  createdAt: Date;
  deviceType: string;
  duration: number;
  ended: Date;
  eventIds: number[];
  id: number;
  projectId: number;
  sessionTypeId: number;
  started: Date;
  targetUserId: number;
  updatedAt: Date;
  userId: number;
};

const isProduction = process.env.NODE_ENV === "production";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "";
const DISABLEANALYTICS = !!process.env.REACT_APP_DISABLE_ANALYTICS;
const SESSIONTYPEID = toNum(process.env.REACT_APP_SESSIONTYPEID, -1);
const SESSIONDEVICETYPE = process.env.REACT_APP_SESSIONDEVICETYPE || "unknown";
const SESSIONTIMEOUT = toNum(process.env.REACT_APP_SESSIONTIMEOUT, 20);
const SESSIONQUEUELENGTH = toNum(process.env.REACT_APP_SESSIONQUEUELENGTH, 30);
let sessionId: null | number = null;
let eventCount = 0;
let sessionQueue: EnrichedAnalyticsEvent[] = [];
let sessionIdRequested = false;

const log = (str = "", data?: any) => {
  if (!isProduction)
    console.log(`ANALYTICS ${str}`, eventCount, sessionQueue, data);
};

const filterEventsToSend = ({
  category,
  action,
  ended,
}: EnrichedAnalyticsEvent) => {
  if (category === "slide" && action === "view" && !ended) return false;
  // more logic when more things are tracked
  return true;
};

const getAllEventsReadyToSend = () => sessionQueue.filter(filterEventsToSend);
const filterOutSentEvents = () =>
  sessionQueue.filter((event) => !filterEventsToSend(event));

// TODO move to utils
const calculateTimeDifferenceInMS = (start: Date, end: Date) =>
  end.getTime() - start.getTime();

const clientConfig: HTTPClientConfig = {
  baseURL: REACT_APP_API_URL,
  timeout: 30000,
};
const httpClient = HTTPClient(clientConfig);

// iterate through the events and close any open, calculating the duration too
const endAllSlideViews = () => {
  sessionQueue = sessionQueue.map((event) => {
    const { category, action, ended, duration } = event;
    if (category === "slide" && action === "view" && !ended && !duration)
      return {
        ...event,
        ended: new Date(),
        duration: calculateTimeDifferenceInMS(event.started, new Date()),
      };
    return event;
  });
};

// returns a session object for the API requests
const buildSessionObject = () => {
  const state = store.getState();
  const story = selectStory(state);
  const sequence = selectSequence(state);
  const user = selectUser(state);
  const accountId = story?.accountId || sequence?.accountId;
  const projectId = story?.projectId || sequence?.projectId;
  return {
    accountId,
    sessionTypeId: SESSIONTYPEID,
    userId: user?.id,
    deviceType: SESSIONDEVICETYPE,
    projectId,
    ended: new Date(),
  };
};

const requestNewSession = () => {
  // do not request duplicates or if sessionId exists
  if (sessionIdRequested || sessionId) return;

  // set true
  sessionIdRequested = true;

  // build the session
  const data: AnalyticsSession = buildSessionObject();

  // we know this is a new session so add the start date
  data.started = sessionQueue[0]?.started || new Date();

  httpClient
    .post<CustomAPIShape, AnalyticsSession>({
      url: "/analytics/events",
      data,
    })
    .then(
      (response) => {
        sessionId = response.data.id;
        log("NEW SESSION ID", sessionId);
        sessionIdRequested = false;
      },
      (error: any) => {
        log("requestNewSession failed", error);
        sessionIdRequested = false;
      }
    );
};

const dispatchAnalytics = (resetSession = false) => {
  // if no events - do nothing
  // note: there should always be a queue as you should be viewing a slide

  if (!sessionQueue.length) return log("NOTHING SENT AS QUEUE IS EMPTY");

  // build the basic session object
  const data: AnalyticsSession = buildSessionObject();

  // is the session exists add the sessionId
  // otherwise set a start date from the first event
  if (sessionId) {
    data.sessionId = sessionId;
  } else {
    data.started = sessionQueue[0]?.started || new Date();
  }

  // add the events
  data.events = getAllEventsReadyToSend();

  // meanwhile reset the queue
  sessionQueue = filterOutSentEvents();
  eventCount = sessionQueue.length;

  // queue up new request
  const isQueued = Beacon(REACT_APP_API_URL + "analytics/events", data, true);
  // isQueued tells us if the browser has queued the request but not any result
  if (!isQueued) return console.error("sendBeacon failed to queue request");

  // then send
  if (resetSession) {
    log("SENT - NOW RESET", data);
    setUpSession();
  } else {
    log("SENT", data);
  }
};

const addEvent = (event: EnrichedAnalyticsEvent) => {
  // add a new event to the queue
  sessionQueue.push({ ...event, sessionTypeId: SESSIONTYPEID });
  eventCount++;
  // if queue reaches limit send batch
  if (eventCount > SESSIONQUEUELENGTH) sendBatch();
};

const setUpSession = () => {
  // just clear the queue and reset sessionId
  sessionQueue = [];
  sessionId = null;
  log("SET UP SESSION");
};

const sendBatch = () => {
  // send all events that are ready to be sent - do not end session
  log("SEND BATCH");
  dispatchAnalytics();
};

const sendAll = () => {
  // close and send all events
  // the app has become blurred - do not end session
  endAllSlideViews();
  log("SEND ALL");
  dispatchAnalytics();
};

const timedOut = () => {
  // the session has timed out
  // if focussed just end the session normally
  // if blurred the session will already be sent
  log("TIMED OUT");
  // if queue has length we need to end the session
  if (sessionQueue.length) return endSession();
  // otherwise because the queue is empty the session is either empty, or already closed
  setUpSession();
};

export const globalStateChange = () => {
  // if parent app does something we just need to close all view events
  endAllSlideViews();
};

export const endSession = () => {
  // HARD end via a button
  // or via a timeout when the app is focussed
  // must end the session
  endAllSlideViews();
  log("SESSION ENDED");
  // if queue has length we need to end the session
  if (sessionQueue.length) return dispatchAnalytics(true);
  // otherwise because the queue is empty the session is either empty, or already closed
  setUpSession();
};

const sessionEvent = SessionManager<EnrichedAnalyticsEvent>(
  setUpSession,
  timedOut,
  SESSIONTIMEOUT
);

// listen for the page to blur or to be closed
PageBlur(sendAll);

export const showhereApi = (hit: EnrichedAnalyticsEvent) => {
  // don't collect hits in iOS if the user has not allowed tracking
  if (isWKWebView && !isWKWebViewTrackingAuthorized()) return;
  // don't collect hits for the listener
  if (role === "listen" || DISABLEANALYTICS) return;
  // if no sessionId request a new one
  if (!sessionId) requestNewSession();
  // track session event to monitor timeout
  sessionEvent(hit);
  // now do custom things
  switch (`${hit.category}_${hit.action}`) {
    case "slide_view":
      addEvent(hit);
      break;
    case "slide_viewed":
      endAllSlideViews();
      break;
    case "userLoggedOut":
      log("User logged out: ", hit);
      break;
    default:
      console.error("Unknown tracking event: ", hit);
      break;
  }
};
