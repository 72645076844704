import { createSlice } from "@reduxjs/toolkit";

const swipeableSlice = createSlice({
  name: "swipeable",
  initialState: {
    animated: { x: true, y: true },
    disabled: false,
  },
  reducers: {
    setAnimated(state, action) {
      state.animated = action.payload;
    },
    setDisabled(state, action) {
      state.disabled = action.payload;
    },
    reset(state) {
      state.animated = { x: true, y: true };
      state.disabled = false;
    },
  },
});

export const { setAnimated, setDisabled, reset } = swipeableSlice.actions;

export default swipeableSlice.reducer;
