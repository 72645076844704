import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import io from "socket.io-client";
import { replaceStructure } from "Store/structure";
// import { replaceSlides } from "Store/slides";
import { syncRemoteConfig, role } from "config";
import { generateRoomCode } from "utilities";

export let socket = null;

export const openSocket = createAsyncThunk(
  "sync-remote/openSocket",
  async (_, store) => {
    socket = io(
      `${syncRemoteConfig.apiUrl}/${process.env.REACT_APP_NAMESPACE}`
    );

    store.dispatch(syncRemoteSlice.actions.setStarted(true));
    store.dispatch(syncRemoteSlice.actions.setFinished(false));

    socket.on("connect", () => {
      store.dispatch(syncRemoteSlice.actions.setConnected(true));

      // join the room
      const { nickname, room } = store.getState().syncRemote;
      socket.emit("join", { nickname, room, role });

      socket.emit("host-starts-presentation", { room });

      if (role === "broadcast") {
        // when host enters room, send presentation state to room
        const { structure, slides } = store.getState();
        socket.emit("send-state", { room, state: { structure, slides } });
      }
    });

    socket.io.on("error", (error) => {
      if (error === "Error: xhr poll error") {
        store.dispatch(syncRemoteSlice.actions.setConnected(false));
      } else {
        console.log("error (other than `Error: xhr poll error`)", error);
      }
    });

    socket.io.on("reconnect", () => {
      store.dispatch(syncRemoteSlice.actions.setConnected(true));
      const { room, nickname } = store.getState().syncRemote;
      socket.emit("reconnect", { room, nickname, role });
    });

    socket.on("disconnect", () => {
      store.dispatch(syncRemoteSlice.actions.setConnected(false));
    });

    if (role === "listen") {
      // handle Redux actions
      socket.on("action", (action) => {
        if (
          action.type.startsWith("structure/") ||
          action.type.startsWith("slides/")
        ) {
          store.dispatch(action);
        }
      });
    }

    // handle state-sync request/response
    if (role === "broadcast") {
      // when viewer joins/reconnects, sync-server emits `request-state` for that user
      socket.on("request-state", ({ requester_id }) => {
        const { structure, slides } = store.getState();
        socket.emit("send-state", {
          recipient_id: requester_id,
          state: { structure, slides },
        });
      });
    } else if (role === "listen") {
      // when host responds to `request-state`, viewer receives state
      socket.on("receive-state", ({ structure, slides }) => {
        store.dispatch(replaceStructure(structure));
        // NB: disabling this temporarily :(
        // Now that data comes from the API and we're setting media base URLs, an iPad will send the wrong base URL to media, thereby breaking all media
        // store.dispatch(replaceSlides(slides));
      });
    }

    if (role === "listen") {
      socket.on("presentation-started", () => {
        store.dispatch(syncRemoteSlice.actions.setFinished(false));
      });
      socket.on("presentation-finished", () => {
        store.dispatch(syncRemoteSlice.actions.setFinished(true));
      });
    }

    socket.on("room-manifest", (manifest) => {
      store.dispatch(syncRemoteSlice.actions.setManifest(manifest));
    });
  }
);

export const closeSocket = () => (dispatch, getState) => {
  dispatch(syncRemoteSlice.actions.setStarted(false));
  dispatch(syncRemoteSlice.actions.setFinished(true));
  if (!socket) {
    return;
  }
  const { nickname, room } = getState().syncRemote;
  socket.emit("leave", { nickname, room });
  if (role === "broadcast") {
    socket.emit("host-ends-presentation", { room });
  }
  socket.io.reconnection(false);
  socket.disconnect();
  socket = null;
};

const syncRemoteSlice = createSlice({
  name: "sync-remote",
  initialState: {
    started: false,
    finished: false,
    connected: false,
    nickname: process.env.REACT_APP_NAMESPACE?.replace(
      /([A-Z])/g,
      " $1"
    ).trim(),
    room: role === "broadcast" ? generateRoomCode() : "",
    manifest: [],
  },
  reducers: {
    setStarted(state, action) {
      state.started = action.payload;
    },
    setFinished(state, action) {
      state.finished = action.payload;
    },
    setConnected(state, action) {
      state.connected = action.payload;
    },
    setNickname(state, action) {
      state.nickname = action.payload;
    },
    setRoom(state, action) {
      state.room = action.payload;
    },
    setManifest(state, action) {
      state.manifest = action.payload;
    },
  },
});

export const { setStarted, setFinished, setNickname, setRoom } =
  syncRemoteSlice.actions;

export const selectScreenshareActive = (state) =>
  state.syncRemote.started === true && state.syncRemote.connected === true;

export const selectScreenshareRoom = (state) => state.syncRemote.room;

export default syncRemoteSlice.reducer;
