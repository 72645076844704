/**
 * Restricts a number between two values
 * @param number  - value to clamp
 * @param lowestValue - the minimum value
 * @param highestValue - the maximum value
 * @returns number
 */
export const clamp = (
  number: number,
  lowestValue: number,
  highestValue: number
) =>
  Math.max(
    Math.min(number, Math.max(lowestValue, highestValue)),
    Math.min(lowestValue, highestValue)
  );

/**
 * Converts a string to a number (can accept numbers in case either are passed)
 * @param input - value to convert
 * @param defaultNumber - what number to return if conversation fails
 * @returns number
 */
export const toNum = (input?: string | number, defaultNumber = 0) => {
  const parsedNumber = parseInt(input as string, 10);
  if (Number.isNaN(parsedNumber)) return defaultNumber;
  return parsedNumber;
};
