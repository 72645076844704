import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    lastSelectedTabs: {},
  },
  reducers: {
    setLastSelectedTab(state, action) {
      const { storyId, tabName } = action.payload;
      state.lastSelectedTabs[storyId] = tabName;
    },
    clearLastSelectedTabs(state) {
      state.lastSelectedTabs = {};
    },
  },
});

export const { setLastSelectedTab, clearLastSelectedTabs } =
  navigationSlice.actions;

export const selectLastSelectedTabs = (state) =>
  state.navigation.lastSelectedTabs;

export default navigationSlice.reducer;
