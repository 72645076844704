import { createAsyncThunk } from "@reduxjs/toolkit";
import { isWKWebView } from "config";
import { setUserPresentationIds } from "Store/auth";
import { getStories } from "Store/stories";

/* 
  These thunks (signIn, getUser, signOut) are executed in two different 
  ways, depending on which platform they're called from. 

  If the requests originates from an iOS WKWebView and that webview exposes 
  a custom postMessage handler called `handleAuth`, then API requests are
  passed up to the webview where they're dispatched with native code.

  In all other cases, we use a direct `window.fetch` request.
*/

const getAuthPlatform = () =>
  isWKWebView && window.webkit?.messageHandlers.handleAuth ? "iOS" : "web";

export const signIn = createAsyncThunk("auth/signIn", (params, thunkAPI) => {
  thunkAPI.dispatch(setUserPresentationIds([]));
  const fetchUrl = `${process.env.REACT_APP_API_URL}auth/login`;
  const payload = { params, thunkAPI, fetchUrl };
  return handleSignIn[getAuthPlatform()](payload);
});

export const getUser = createAsyncThunk("auth/getUser", (params, thunkAPI) => {
  const fetchUrl = `${process.env.REACT_APP_API_URL}user/me`;
  const payload = { thunkAPI, fetchUrl };
  return handleGetUser[getAuthPlatform()](payload);
});

export const signOut = createAsyncThunk("auth/signOut", (params, thunkAPI) => {
  const fetchUrl = `${process.env.REACT_APP_API_URL}auth/logout`;
  const payload = { thunkAPI, fetchUrl };
  return handleSignOut[getAuthPlatform()](payload);
});

const handleSignIn = {
  iOS: async ({ params, thunkAPI, fetchUrl }) => {
    try {
      const response = await new Promise((resolve, reject) => {
        window.addEventListener("handleAuth.signIn", ({ detail: event }) => {
          if (event.status === "ok") {
            resolve({ user: event.user, authToken: event.authToken });
          } else {
            reject({ message: event.message });
          }
        });
        window.webkit?.messageHandlers.handleAuth.postMessage({
          action: "signIn",
          payload: {
            endpoint: fetchUrl,
            email: params.email,
            password: params.password,
          },
        });
      });
      window.localStorage.setItem("auth-token", response.authToken);
      thunkAPI.dispatch(getStories());
      return response.user;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  },
  web: async ({ params, thunkAPI, fetchUrl }) => {
    try {
      const response = await window.fetch(fetchUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token-type": "sync",
        },
        credentials: "include",
        method: "POST",
        body: JSON.stringify(params),
      });
      if (response.status === 401) {
        throw new Error(
          `We couldn't log you in with these details. 
          Please check your email address and password.`
        );
      }
      if (!response.ok) {
        throw new Error(response.status);
      }
      const user = await response.json();
      thunkAPI.dispatch(getStories());
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  },
};

const handleGetUser = {
  iOS: async ({ thunkAPI, fetchUrl }) => {
    try {
      const response = await new Promise((resolve, reject) => {
        window.addEventListener("handleAuth.getUser", ({ detail: event }) => {
          console.log(event);
          if (event.status === "ok") {
            resolve({ user: event.user });
          } else {
            reject({ message: event.message });
          }
        });
        window.webkit?.messageHandlers.handleAuth.postMessage({
          action: "getUser",
          payload: {
            endpoint: fetchUrl,
            authToken: window.localStorage.getItem("auth-token"),
          },
        });
      });
      thunkAPI.dispatch(getStories());
      return response.user;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  },
  web: async ({ thunkAPI, fetchUrl }) => {
    try {
      const response = await window.fetch(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token-type": "sync",
        },
        credentials: "include",
      });
      if (!response.ok) {
        const { message = "getUser failed" } = await response.json();
        throw new Error(message);
      }
      const user = await response.json();
      thunkAPI.dispatch(getStories());
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  },
};

const handleSignOut = {
  iOS: async ({ thunkAPI, fetchUrl }) => {
    try {
      await new Promise((resolve, reject) => {
        if (window.navigator.isOnline === false) {
          reject({ message: "Blocked signout when offline" });
          return;
        }
        window.addEventListener("handleAuth.signOut", ({ detail: event }) => {
          console.log("handleAuth.signOut response from swift", event);
          event.status === "ok"
            ? resolve()
            : reject({ message: event.message });
        });
        window.webkit?.messageHandlers.handleAuth.postMessage({
          action: "signOut",
          payload: {
            endpoint: fetchUrl,
            token: window.localStorage.getItem("auth-token"),
          },
        });
      });
      window.localStorage.removeItem("auth-token");
      return;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  },
  web: async ({ thunkAPI, fetchUrl }) => {
    try {
      const response = await window.fetch(fetchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token-type": "sync",
        },
        credentials: "include",
      });
      if (!response.ok) throw new Error(response.status);
      return;
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  },
};
