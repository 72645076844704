import { transformApiResponseSlide } from "@showhereco/slide-types";

export function TransformApiResponse({
  presentations,
  mediaBaseUrlTemplate,
  screenCapsBaseUrlTemplate,
}) {
  try {
    return presentations
      .filter(({ name, id, latestCompiledPresentationData: publishedData }) => {
        if (!publishedData) {
          console.error(
            `Missing compiled presentation data for "${name}" (id: ${id})`
          );
          return false;
        }
        if (!publishedData.mediaManifest) {
          console.error(`Missing media manifest for "${name}" (id: ${id})`);
          return false;
        }
        return true;
      })
      .sort((a, b) => a.order - b.order)
      .map((p) => {
        const mediaBaseUrl = mediaBaseUrlTemplate
          .replace("#PRES_ID#", p.id)
          .replace(
            "#PRES_VERSION#",
            p.latestCompiledPresentationData?.version ?? 1
          );

        let storyThumbnail = null;
        const bannerMedia = p.settingsData?.bannerMedia;
        if (bannerMedia?.length) {
          storyThumbnail = `${mediaBaseUrl}${bannerMedia[0].fileName}`;
        }

        const navThumbs = [];
        const screenCapsBaseUrl = screenCapsBaseUrlTemplate
          .replace("#PRES_ID#", p.id)
          .replace(
            "#PRES_VERSION#",
            p.latestCompiledPresentationData?.version ?? 1
          );
        p.screenCapManifest?.forEach(({ slideId, screenCapFilename }) => {
          if (!navThumbs[slideId]) {
            navThumbs[slideId] = `${screenCapsBaseUrl}${screenCapFilename}`;
          }
        });

        const columnGroups =
          p.latestCompiledPresentationData.body.columnGroups.map((cg) => ({
            title: cg.name,
            columns: cg.columns.map((col) => ({
              thumbnail: col.thumbnail
                ? `${mediaBaseUrl}${col.thumbnail.fileName}`
                : null,
              slides: col.slides?.length
                ? col.slides.map((slide) =>
                    transformApiResponseSlide({ slide, mediaBaseUrl })
                  )
                : [],
            })),
          }));

        const sequences = p.sequences
          .filter(({ version }) => version !== null)
          .filter(({ slides }) => slides?.length > 0)
          .sort((a, b) => {
            // sort by newest to oldest
            const aTime = new Date(a.updatedAt).getTime();
            const bTime = new Date(b.updatedAt).getTime();
            return bTime - aTime;
          })
          .map((sequence) => ({
            id: sequence.id,
            version: sequence.version,
            name: sequence.name,
            description: sequence.description,
            publishedAt: sequence.updatedAt,
            slides:
              sequence.slides?.map((slide) => ({
                ...transformApiResponseSlide({
                  slide,
                  mediaBaseUrl,
                }),
                thumbnail: navThumbs[slide.id],
              })) ?? [],
            disabled: sequence.contentStatus !== "published",
          }));

        return {
          accountId: p.accountId,
          projectId: p.projectId,
          id: p.id,
          version: p.latestCompiledPresentationData.version,
          publishedAt: p.latestCompiledPresentationData.updatedAt,
          order: p.order,
          mediaArchiveUrl:
            p.latestCompiledPresentationData.mediaManifest.archiveLocation,
          archived: p.archived,
          title: p.name,
          thumbnail: storyThumbnail,
          columnGroups,
          sequences,
        };
      });
  } catch (err) {
    console.error(err);
  }
}
