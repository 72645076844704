import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import io from "socket.io-client";
import { role } from "config";
import { getPersistedState } from "./middleware/local-storage";

export const STORAGE_KEY = "showhere-sync-local-settings";

const defaultState = {
  enabled: false,
  host: "0.0.0.0",
  port: 3333,
};
const persistedState = getPersistedState(STORAGE_KEY, defaultState);
const initialState = persistedState ?? defaultState;

export let socket = null;

function createSocketConnected(io, url, options) {
  let i = 0;
  return new Promise(function (resolve, reject) {
    const socket = io(url, options);
    const pollConnected = setInterval(() => {
      i++;
      if (i >= 120) {
        // reject promise after 1 min
        clearInterval(pollConnected);
        reject();
      }
      if (socket?.connected) {
        clearInterval(pollConnected);
        resolve(socket);
      }
    }, 500);
  });
}

export const connectSocket = createAsyncThunk(
  "sync-local/connectSocket",
  async (_, store) => {
    const { host, port } = store.getState().syncLocal;
    socket = await createSocketConnected(io, `http://${host}:${port}`);

    if (role === "listen") {
      socket.on("action", (action) => {
        if (
          action.type.startsWith("structure/") ||
          action.type.startsWith("slides/")
        ) {
          store.dispatch(action);
        }
      });
    }
  }
);

export const disconnectSocket = () => (dispatch) => {
  if (!socket) {
    return;
  }
  socket.io.reconnection(false);
  socket.disconnect();
  socket = null;
  dispatch(syncLocalSlice.actions._disconnectSocket());
};

const syncLocalSlice = createSlice({
  name: "sync-local",
  initialState: { ...initialState, connected: false },
  reducers: {
    setEnabled(state, action) {
      state.enabled = action.payload;
    },
    setHost(state, action) {
      state.host = action.payload;
    },
    setPort(state, action) {
      state.port = action.payload;
    },
    _disconnectSocket(state) {
      state.connected = false;
    },
  },
  extraReducers: {
    [connectSocket.pending]: (state, action) => {
      state.connected = false;
    },
    [connectSocket.fulfilled]: (state, action) => {
      state.connected = true;
    },
    [connectSocket.rejected]: (state, action) => {
      state.connected = false;
    },
  },
});

export const { setEnabled, setHost, setPort } = syncLocalSlice.actions;

export default syncLocalSlice.reducer;
