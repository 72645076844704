import { role } from "config";
import { socket as localSocket } from "Store/syncLocal";
import { socket as remoteSocket } from "Store/syncRemote";

const syncEmitter =
  (target = null) =>
  (store) =>
  (next) =>
  (action) => {
    if (unavailableSocket(target) || outOfScope(action.type)) {
      return next(action);
    }

    if (action.type === "structure/resetStoryId") {
      return next(action);
    }

    switch (target) {
      case "local":
        localSocket.emit("action", action);
        break;
      case "remote":
        const { nickname, room } = store.getState().syncRemote;
        action.meta = { nickname, room, role };
        remoteSocket.emit("action", action);
        break;
      default:
        console.warn("Unhandled syncEmitter target", target);
    }

    return next(action);
  };

function unavailableSocket(target) {
  return (
    target === null ||
    (target === "local" && localSocket === null) ||
    (target === "remote" && remoteSocket === null)
  );
}

function outOfScope(actionType) {
  return (
    !actionType.startsWith("structure/") && !actionType.startsWith("slides/")
  );
}

export default syncEmitter;
