export const getPersistedState = (key = null, defaultState = {}) => {
  try {
    const item = window.localStorage.getItem(key);
    if (item === null) {
      window.localStorage.setItem(key, JSON.stringify(defaultState));
      return defaultState;
    }
    return JSON.parse(item);
  } catch (err) {
    console.log(err);
  }
};

export const persistState =
  (key = null, criteria = []) =>
  (store) =>
  (next) =>
  (action) => {
    if (key === null) {
      return;
    }
    if (Object.keys(criteria).includes(action.type)) {
      const state = getPersistedState(key);
      state[criteria[action.type]] = action.payload;
      window.localStorage.setItem(key, JSON.stringify(state));
    }
    return next(action);
  };
