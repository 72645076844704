import React, { useEffect, Suspense, lazy } from "react";
import * as Sentry from "@sentry/react";
import { Provider, useSelector } from "react-redux";
import { TitleBar } from "@showhereco/slide-ui";
import { AnalyticsListener, AnalyticsManager } from "@showhereco/slide-types";
import { OnlineStatusProvider, useOnlineStatus } from "./useOnlineStatus";
import store from "Store";
import { selectUser, selectIsLoading as selectUserIsLoading } from "Store/auth";
import { getUser } from "Store/auth/thunks";
import { getStories, selectStories, selectFirstFetch } from "Store/stories";
import { selectSlide } from "Store/slides";
import { connectSocket as connectLocalSocket } from "Store/syncLocal";
import StaticRenderer from "SlidesRenderer/StaticRenderer";
import {
  role,
  target,
  isOfflineFirst,
  isMarketingSuiteViewer,
  dataProvider,
} from "config";
import "./App.css";
import {
  analyticsModel,
  AnalyticsEvent,
  EnrichedAnalyticsEvent,
  showhereApi,
} from "./Analytics";

const SignInForm = lazy(() => import("SignInForm"));
const Navigation = lazy(() => import("Navigation"));
const SwipeableSlides = lazy(() => import("SlidesRenderer/SwipeableSlides"));
const SlideControls = lazy(() => import("SlidesRenderer/SlideControls"));
const Notifications = lazy(() => import("Notifications"));
const PassiveSlides = lazy(() => import("SlidesRenderer/PassiveSlides"));
const RemoteSlides = lazy(() => import("SlidesRenderer/RemoteSlides"));

// create Analytics Manager and connected suites
const analyticsManager = AnalyticsManager<EnrichedAnalyticsEvent>([
  showhereApi,
]);

// create Analytics Listener and re-export the hook at the bottom of this file
const { AnalyticsProvider, useAnalytics } = AnalyticsListener<
  AnalyticsEvent,
  EnrichedAnalyticsEvent
>({
  analyticsModel,
  analyticsManager,
});

// listen for iOS-native App Tracking Transparency modal response
type AppTrackingTransparencyAuthorizationStatusOptions =
  | "authorized"
  | "denied"
  | "restricted"
  | "notDetermined"
  | "unknown";
window.addEventListener("AppTrackingTransparency", ((ev: CustomEvent) => {
  const status: AppTrackingTransparencyAuthorizationStatusOptions =
    ev.detail.AuthorizationStatus;
  (window as any).AppTrackingTransparencyAuthorizationStatus = status;
}) as EventListener);

function App() {
  useEffect(() => {
    /*
    if (role !== "static") {
      if (dataProvider === "api:auth") {
        store.dispatch(getUser());
      } else if (dataProvider === "api:key" || dataProvider === "disk") {
        store.dispatch(getStories());
      }
    }
    */

    if (role === "static") return;
    if (role === "broadcast") {
      store.dispatch(dataProvider === "api:auth" ? getUser() : getStories());
    } else if (role === "listen") {
      if (dataProvider === "api:auth") {
        if (typeof process.env.REACT_APP_API_CONTENT_KEY !== "undefined") {
          console.warn(
            "[1/2] Overriding dataProvider from `api:auth` to `api:key` for role `listen`"
          );
          store.dispatch(getStories());
        } else {
          console.warn(
            "TODO implement data fetching for role `listen` when there is no content key to fall back on"
          );
        }
      } else {
        store.dispatch(getStories());
      }
    }
  });

  useHideMouse(isMarketingSuiteViewer);

  const { syncLocal } = store.getState();
  if (syncLocal.enabled) store.dispatch(connectLocalSocket());

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <AnalyticsProvider>
        <OnlineStatusProvider>
          <Provider store={store}>
            {role === "broadcast" && (
              <Suspense fallback={<Loading />}>
                <Presenter />
              </Suspense>
            )}
            {role === "listen" && (
              <Suspense fallback={<Loading />}>
                {target === "local" && <PassiveSlides />}
                {target === "remote" && <RemoteSlides />}
              </Suspense>
            )}
            {role === "static" && (
              <Suspense fallback={<Loading />}>
                <StaticRenderer />
              </Suspense>
            )}
          </Provider>
        </OnlineStatusProvider>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  );
}

function Loading() {
  return (
    <div className="loading-screen">
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          opacity=".2"
          d="M16 32a16 16 0 1 1 0-32 16 16 0 0 1 0 32Zm0-28a12 12 0 1 0 0 24 12 12 0 0 0 0-24Z"
          fill="#444"
        />
        <path
          d="M32 16h-4A12 12 0 0 0 16 4V0a16 16 0 0 1 16 16Z"
          fill="#13683E"
        />
      </svg>
    </div>
  );
}

function useHideMouse(enabled: boolean) {
  useEffect(() => {
    if (enabled) {
      let mouseTimer: number | null = null;
      let cursorVisible = true;
      function disappearCursor() {
        mouseTimer = null;
        cursorVisible = false;
        document.body.classList.add("hide-cursor");
      }
      document.onmousemove = function () {
        if (mouseTimer) {
          window.clearTimeout(mouseTimer);
        }
        if (!cursorVisible) {
          document.body.classList.remove("hide-cursor");
          cursorVisible = true;
        }
        mouseTimer = window.setTimeout(disappearCursor, 2000);
      };
    }
  });
  return null;
}

function ErrorFallback() {
  return (
    <div className="error-fallback" onClick={() => window.location.reload()}>
      <div className="error-fallback__body">
        <h1>An error has occurred</h1>
        <p>Tap anywhere to reload the presentation</p>
      </div>
      <div className="error-fallback__footer">
        <p>Technical support has been notified</p>
      </div>
    </div>
  );
}

function Presenter() {
  const isOnline = useOnlineStatus();

  const user = useSelector(selectUser);
  const userIsLoading = useSelector(selectUserIsLoading);
  const { error, loading } = useSelector(selectFirstFetch);
  const stories = useSelector(selectStories);
  const slide = useSelector(selectSlide);

  if (dataProvider === "api:auth") {
    const isLoggedIn = !!user;
    if (isLoggedIn) {
      return (
        <>
          <SwipeableSlides />
          <TitleBar text={slide?.titleBar ?? slide?.title} />
          <SlideControls />
          <Navigation stories={stories} />
          <Notifications />
        </>
      );
    }
    if (!isLoggedIn || userIsLoading) {
      return <SignInForm />;
    }
  }

  if (error && !isOfflineFirst) {
    return (
      <div className="loading-error" onClick={() => window.location.reload()}>
        <div className="errors">
          <p className="xxl">Presentations could not be loaded&hellip;</p>
          {isOnline === false && (
            <p>
              You appear to be offline. <br />
              Please connect and try again.
            </p>
          )}
          <pre>{error}</pre>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SwipeableSlides />
      <TitleBar text={slide?.titleBar ?? slide?.title} />
      <SlideControls />
      {stories.length > 0 && <Navigation stories={stories} />}
      <Notifications />
    </>
  );
}

export { App as default, useAnalytics };
