import { createSlice, nanoid } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    addNotification(state, action) {
      const defaults = {
        id: nanoid(),
        appearance: "info",
        text: null,
      };
      state.push({ ...defaults, ...action.payload });
    },
    removeNotification(state, action) {
      const index = state.findIndex(({ id }) => id === action.payload);
      state.splice(index, 1);
    },
  },
  extraReducers: {
    "sync-local/connectSocket/rejected": (state, action) => {
      state.push({
        id: nanoid(),
        text: "Failed to connect to local sync server",
        appearance: "error",
      });
    },
    "sync-remote/connectSocket/rejected": (state, action) => {
      state.push({
        id: nanoid(),
        text: "Failed to connect to remote sync server",
        appearance: "error",
      });
    },
  },
});

export const { addNotification, removeNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
