import { isOfflineFirst } from "config";

export const STORAGE_KEY = "showhere-auth-user";

export const getPersistedUser = () =>
  JSON.parse(window.localStorage.getItem(STORAGE_KEY)) ?? undefined;

export const setPersistedUser = (user) =>
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(user));

export const removePersistedUser = () =>
  window.localStorage.removeItem(STORAGE_KEY);

  if (isOfflineFirst === false) {
    removePersistedUser();
  }