import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import * as Sentry from "@sentry/react";
import { isOfflineFirst } from "config";
import { appFeatures, role } from "config";
import authReducer from "./auth";
import navigationReducer from "./navigation";
import notificationsReducer from "./notifications";
import storiesReducer from "./stories";
import storiesOfflineFirstManagerReducer, {
  STORAGE_KEY as STORAGE_KEY_STORIESOFFLINE,
} from "./storiesOfflineFirstManager";
import structureReducer from "./structure";
import slidesReducer from "./slides";
import swipeableReducer from "./swipeable";
import syncLocalReducer, {
  STORAGE_KEY as STORAGE_KEY_SYNCLOCAL,
} from "./syncLocal";
import syncRemoteReducer from "./syncRemote";
import { persistState } from "./middleware/local-storage.js";
import syncEmitter from "./middleware/sync-emitter.js";

const store = configureStore({
  reducer: {
    auth: authReducer,
    stories: storiesReducer,
    storiesOfflineFirstManager: storiesOfflineFirstManagerReducer,
    structure: structureReducer,
    slides: slidesReducer,
    swipeable: swipeableReducer,
    syncLocal: syncLocalReducer,
    syncRemote: syncRemoteReducer,
    notifications: notificationsReducer,
    navigation: navigationReducer,
  },

  middleware: (getDefaultMiddleware) => {
    let mw;

    if (!!process.env.REACT_APP_ENABLE_LOGGING)
      mw = getDefaultMiddleware().concat(createLogger({ collapsed: true }));
    else mw = getDefaultMiddleware();

    // stories persist to local storage
    if (isOfflineFirst === true) {
      function getPersistedStories() {
        const stories = window.localStorage.getItem(STORAGE_KEY_STORIESOFFLINE);
        return stories ? JSON.parse(stories) : [];
      }
      function setPersistedStories(stories) {
        window.localStorage.setItem(
          STORAGE_KEY_STORIESOFFLINE,
          JSON.stringify(stories.sort((a, b) => a.order - b.order))
        );
      }
      const persistStories = (store) => (next) => (action) => {
        const { type, payload } = action;
        try {
          if (type === "stories/setStories") {
            setPersistedStories(payload);
            console.info("[persistStories] All stories persisted");
          } else if (type === "stories/addStory") {
            let stories = getPersistedStories();
            const storyIdx = stories.findIndex(({ id }) => id === payload.id);
            if (storyIdx === -1) {
              stories.push(payload);
            } else {
              stories[storyIdx] = payload;
            }
            setPersistedStories(stories);
            console.info(`[persistStories] "${payload.title}" added`);
          } else if (type === "stories/removeStory") {
            let stories = getPersistedStories();
            const storyIdx = stories.findIndex(({ id }) => id === payload.id);
            if (storyIdx === -1) throw Error("No such story");
            stories.splice(storyIdx, 1);
            setPersistedStories(stories);
            console.info(`[persistStories] "${payload.title}" removed`);
          } else if (type === "stories/addSequence") {
            let stories = getPersistedStories();
            const storyIdx = stories.findIndex(
              ({ id }) => id === payload.story.id
            );
            if (storyIdx === -1) throw Error("No such story");
            if (stories[storyIdx].sequences) {
              stories[storyIdx].sequences.push(payload.sequence);
            } else {
              stories[storyIdx].sequences = [payload.sequence];
            }
            setPersistedStories(stories);
            console.info(`[persistSequences] "${payload.sequence.name}" added`);
          } else if (type === "stories/updateSequence") {
            let stories = getPersistedStories();
            const storyIdx = stories.findIndex(
              ({ id }) => id === payload.story.id
            );
            if (storyIdx === -1) throw Error("No such story");
            const sequenceIdx = stories[storyIdx].sequences.findIndex(
              ({ id }) => id === payload.sequence.id
            );
            if (sequenceIdx === -1) throw Error("No such sequence");
            stories[storyIdx].sequences[sequenceIdx] = payload.sequence;
            setPersistedStories(stories);
            console.info(
              `[persistSequences] "${payload.sequence.name}" updated`
            );
          } else if (type === "stories/removeSequence") {
            let stories = getPersistedStories();
            const storyIdx = stories.findIndex(
              ({ id }) => id === payload.story.id
            );
            if (storyIdx === -1) throw Error("No such story");
            const sequenceIdx = stories[storyIdx].sequences.findIndex(
              ({ id }) => id === payload.sequence.id
            );
            if (sequenceIdx === -1) throw Error("No such sequence");
            stories[storyIdx].sequences.splice(sequenceIdx, 1);
            setPersistedStories(stories);
            console.info(
              `[persistSequences] "${payload.sequence.name}" removed`
            );
          }
        } catch (err) {
          console.warn("[middleware:persist*] Failed", action);
          console.error(err);
        }
        return next(action);
      };
      mw.push(persistStories);
    }

    if (role === "broadcast") {
      // local sync
      if (appFeatures.includes("sync-local")) {
        mw.push(
          persistState(STORAGE_KEY_SYNCLOCAL, {
            "sync-local/setEnabled": "enabled",
            "sync-local/setHost": "host",
            "sync-local/setPort": "port",
          }),
          syncEmitter("local")
        );
      }

      // remote sync
      if (appFeatures.includes("sync-remote")) {
        mw.push(syncEmitter("remote"));
      }
    }

    return mw;
  },
  enhancers: [Sentry.createReduxEnhancer()],
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
